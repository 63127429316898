<template>
    <div class="container mt-5">

        <!-- SHOPS -->
        <div class="text-center">
            <div class="form-check form-check-inline m-2" v-for="shop in shops" :key="shop.id">
                <input class="form-check-input" type="radio" name="shop" :id="'checkbox-' + shop.id" :value="shop.slug"
                    v-model="shopParent.shop" @change="updateUrlParams">
                <label class="form-check-label" :for="'checkbox-' + shop.id">{{ shop.name }}</label>
            </div>
        </div>
        <br><br>

        <!-- STATUS -->
        <div class="text-center" v-if="ordersStatuses">
            <div class="form-check form-check-inline m-2" v-for="status in ordersStatuses" :key="status.id">
                <input class="form-check-input" type="radio" name="status" :id="'checkboxStatus-' + status.id"
                    :value="status.query" v-model="shopParent.status" @change="updateUrlParams">
                <label class="form-check-label" :for="'checkboxStatus-' + status.id">{{ status.name }} ({{ status.data
                    }})</label>
            </div>
        </div>
        <br><br>

        <!-- SEARCH -->
        <div style="text-align: center">
            <label for="fname">Search:</label>
            <input type="text" name="code_order" id="search_value" value="">
            <button type="button" class="btn btn-primary btn_search" v-on:click="getTickets()">Submit</button>
        </div>

        <br><br>

        <!-- TITLE -->
        <h1 class="text-center">Tickets
            <div type="button" class="btn btn-primary" v-on:click="openModalCreateOrder">
                <b>+</b>
            </div>
        </h1>

        <br><br>

        <table class="table table-hover" v-if="tickets.length > 0">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Code</th>
                    <th>Status</th>
                    <th>Channel</th>
                    <th>Product</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Items</th>
                    <th>Client</th>
                    <th>Phone</th>
                    <th>Destination</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="order in tickets" :key="order.id" @click="selectOrder(order.id)"
                    @dblclick="openModalUpdate()">
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">{{ order.id }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">{{ order.code_order }}
                    </td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">
                        <span v-if="order.status && order.status.name">{{ order.status.name }}</span>
                        <span v-else></span>
                    </td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item"
                        v-if="order.shop_store && order.shop_store.name">{{ order.shop_store.name }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item" v-else></td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item"
                        v-if="order.orders && order.orders.length == 1 && order.orders[0].product">{{
                            order.orders[0].product.title_small || order.orders[0].product.title}}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item" v-else>Variado</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">{{ order.created_at }}
                    </td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">{{ order.customer }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item"
                        v-if="order.orders && order.orders.length">{{ order.orders.length }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item" v-else>0</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item"
                        v-if="order.order_client && order.order_client.name">{{ order.order_client.name }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item" v-else></td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item">
                        <div v-if="order.order_client && order.order_client.phone">{{ order.order_client.phone }}</div>
                        <div v-else>NO PHONE</div>
                    </td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item"
                        v-if="order.order_destination && order.order_destination.district">{{
                            order.order_destination.district }}</td>
                    <td :class="{ selected: order.id === selectedOrderId }" class="order-item" v-else></td>
                </tr>
            </tbody>
        </table>

        <!-- BTN ACTION - ORDER -->
        <div v-if="selectedOrderId && filteredTicket" class="fixed-actions">
            <div class="row">
                <div class="col"><b>Ticket: </b> {{ filteredTicket.id }}</div>
                <div class="col"><b>Code: </b> {{ filteredTicket.code_order }}</div>
                <div class="col"><b>Status: </b> {{ filteredTicket.status.name }}</div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <ul>
                        <li v-for="order in filteredTicket.orders" :key="order.id">
                            <b>Products: </b> <span v-if="order.product">{{ order.product.title_small ||
                                order.product.title }}</span> - <b>Cantidad: </b> {{ order.amount }}
                        </li>
                    </ul>
                </div>
            </div>
            <button @click="copy_wsp_abandoned" class="btn btn-primary">Abandoned</button>
            <button @click="copy_wsp_buys" class="btn btn-primary">Buys</button>
            <button @click="copy_wsp_delivery" class="btn btn-primary">C. Delivery</button>
            <button @click="copy_wsp_client" class="btn btn-primary">C. Overview</button>
            <button @click="generatePDF" class="btn btn-primary">Ticket Print</button>
            <button @click="editOrder(selectedOrderId)" class="btn btn-primary">Edit</button>
            <button @click="ticketDelete(selectedOrderId)" class="btn btn-danger">Eliminar</button>
        </div>

        <!-- Modal -->
        <div class="modal" v-if="selectedOrderId && filteredTicket">
            <div class="modal-content">
                <span class="modal-close" @click="closeModal">&times;</span>
                <h2>Detalles de la Orden {{ filteredTicket }}</h2>
                <p>Aquí van los detalles de la orden seleccionada...</p>
                <a @click="editOrder(selectedOrderId)">Editar</a>
                <a @click="deleteOrder(selectedOrderId)">Eliminar</a>
            </div>
        </div>

        <!-- Button trigger modal - CREATE ORDER -->
        <a type="button" id="btnModalCreateOrder" hidden class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#orderModalCreate">
            Launch demo modal
        </a>
        <!-- Button trigger modal - EDIT ORDER -->
        <a type="button" id="btnModal" hidden class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#orderModal">
            Launch demo modal
        </a>

        <!-- Bootstrap Modal - CREATE ORDER -->
        <div class="modal fade" id="orderModalCreate" tabindex="-1" aria-labelledby="orderModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="orderModalLabel">Create order</h5>
                        <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
                    </div>
                    <div class="modal-body">
                        <modelCreateOrder ref="createOrderComponent" />
                    </div>
                    <div class="modal-footer">
                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
                        <a type="button" class="btn btn-primary" @click="saveTicket">Save</a>
                        <a type="button" class="btn btn-primary" @click="editOrder(selectedOrderId)">Editar</a>
                        <a type="button" class="btn btn-danger" @click="deleteOrder(selectedOrderId)">Eliminar</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Bootstrap Modal - EDIT ORDER -->
        <div class="modal fade" id="orderModal" tabindex="-1" aria-labelledby="orderModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="orderModalLabel">Detalles de la Orden {{ selectedOrderId }}</h5>
                        <a type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></a>
                    </div>
                    <div class="modal-body">
                        <modelUpdateeOrder :itemTicket="filteredTicket" ref="updateOrderComponent" />
                    </div>
                    <div class="modal-footer">
                        <a type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</a>
                        <a type="button" class="btn btn-primary" @click="generatePDF()">Imprimir</a>
                        <a type="button" class="btn btn-primary" @click="updateOrderData()">Update</a>
                        <a type="button" class="btn btn-danger" @click="deleteOrder(selectedOrderId)">Eliminar</a>
                    </div>
                </div>
            </div>
        </div>

        <button @click="generatePDF">Generate PDF</button>
        <iframe ref="pdfFrame"></iframe>

         <textarea ref="textAreaCopy" cols="80" rows="20"></textarea>

        <div style="height: 150px;"></div>

    </div>
</template>

<script>
import axios from 'axios'
import { jsPDF } from 'jspdf';

import modelCreateOrder from '@/components/tickets/modal/createOrder.vue'
import modelUpdateeOrder from '@/components/tickets/modal/updateOrder.vue'


export default {
    name: 'cTickets',
    components: {
        modelCreateOrder,
        modelUpdateeOrder
    },
    data() {
        return {
            orderData: {},
            tickets: [
                { id: 1, description: 'Orden 1', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 2, description: 'Orden 2', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 3, description: 'Orden 3', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 4, description: 'Orden 4', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 5, description: 'Orden 5', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 6, description: 'Orden 6', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 7, description: 'Orden 7', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 8, description: 'Orden 8', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 9, description: 'Orden 9', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 10, description: 'Orden 10', code: 'abcd12', customer: 'John', method: 'Shipping' },
                { id: 39, description: 'Orden 11', code: 'abcd12', customer: 'John', method: 'Shipping' },
            ],
            ordersStatuses: [],
            selectedOrderId: null,
            shopParent: {
                status: '',
                search: '',
                shop: 'tiendapapaya',
            },
            shopSelect: 'tiendapapaya',
            shops: [
                { id: 1, name: "TP", slug: "tiendapapaya" },
                { id: 3, name: "PM", slug: "pequemundos" },
                { id: 10, name: "PC", slug: "publicidadcapital" },
                { id: 4, name: "NONE", slug: "" },
            ],
            selectOrderData: {},
            statusModalUpdate: false,
        }
    },
    created() {
        this.getTickets();
        this.getStatus();
        this.$store.dispatch("tickets/getStatuses");
        // this.$store.dispatch("navigation/setNavigation");
    },
    mounted() {
        this.initParamsFromUrl();
        this.getProductsShops();
    },
    methods: {
        getProductsShops() {
            console.log("getProductsShops")
            this.$store.dispatch("shopsProducts/getProducts");
        },
        updateOrderData() {
            console.log("updateOrderData")
            // this.orderData = data;
            // Acceder a los datos del componente hijo
            const orderData = this.$refs.updateOrderComponent.getOrderData();
            console.log(orderData)

            const url = this.appDomainApi + "shops/tickets/update"
            // const params = {
            //     body: this.orderData
            // }
            axios.post(url, orderData.ticket)
                .then(res => {
                    console.log(res.data.data)
                    // this.filteredTicket = res.data.data;
                    this.$refs.updateOrderComponent.refreshTicket();
                })
                .catch(err => {
                    console.error(err);
                });

        },
        ticketPrint() {
            console.log("updateOrderData")
            // this.orderData = data;
            // Acceder a los datos del componente hijo
            const orderData = this.$refs.updateOrderComponent.getOrderData();
            console.log(orderData)

            const url = this.appDomainApi + "shops/tickets/update"
            // const params = {
            //     body: this.orderData
            // }
            axios.post(url, orderData)
                .then(res => {
                    console.log(res.data.data)
                    // this.filteredTicket = res.data.data;
                    this.$refs.updateOrderComponent.refreshTicket();
                })
                .catch(err => {
                    console.error(err);
                });

        },
        getStatus() {
            const url = this.appDomainApi + "shops/tickets/status";
            axios.get(url,)
                .then(res => {
                    console.log(res)
                    this.ordersStatuses = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getTickets() {
            const url = this.appDomainApi + "shops/tickets/index" + window.location.search;
            this.selectedOrderId = null;
            axios.get(url,)
                .then(res => {
                    console.log("getTickets")
                    console.log(res)
                    this.tickets = res.data.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        ticketDelete(item_id) {
            console.log("ticketDelete")
            // const url = this.appDomainApi + "shops/ticket/delete/" + item_id;

            // console.log(url)
            // this.selectedOrderId = null;
            // axios.delete(url,)
            //     .then(res => {
            //         console.log("getTickets")
            //         console.log(res)
            //         // this.tickets = res.data.data
            //         this.tickets = this.tickets.filter(ticket => ticket.id !== item_id);
            //     })
            //     .catch(err => {
            //         console.error(err);
            //     })
        },
        selectOrder(orderId) {
            console.log("selectOrder: " + orderId)
            this.selectedOrderId = orderId;
        },
        openModalUpdate() {
            console.log("openModalUpdate: " + this.selectedOrderId)
            // this.selectedOrderId = orderId;
            this.statusModalUpdate = true
            // Mostrar el modal aquí
            // document.querySelector('.modal').style.display = 'block';

            // Cambiar aria-hidden a false usando JavaScript
            // const modal = document.getElementById('orderModal');
            // modal.setAttribute('aria-hidden', 'false');
            // $('#orderModal').modal('show');
            document.getElementById('btnModal').click();
        },
        openModalCreateOrder(orderId) {
            // this.selectedOrderId = orderId;
            // Mostrar el modal aquí
            // document.querySelector('.modal').style.display = 'block';

            // Cambiar aria-hidden a false usando JavaScript
            // const modal = document.getElementById('orderModal');
            // modal.setAttribute('aria-hidden', 'false');
            // $('#orderModal').modal('show');
            document.getElementById('btnModalCreateOrder').click();
        },
        closeModal() {
            this.selectedOrderId = null;
            // Cerrar el modal aquí
            document.querySelector('.modal').style.display = 'none';
        },
        editOrder(orderId) {
            alert(`Editando la orden con ID: ${orderId}`);
        },
        deleteOrder(orderId) {
            alert(`Eliminando la orden con ID: ${orderId}`);
        },
        updateUrlParams() {
            console.log("updateUrlParams")
            // Actualiza la URL cuando cambia el estado de los checkboxes
            window.history.replaceState({}, '', this.urlWithParams);
        },
        initParamsFromUrl() {
            console.log("initParamsFromUrl")
            const urlParams = new URLSearchParams(window.location.search);
            console.log(urlParams)

            // Obtener los parámetros de los checkboxes
            this.shopParent.status = urlParams.get('status');
            this.shopParent.code = urlParams.get('code_order');
            this.shopParent.search = urlParams.get('search');
            // this.shopParent.shop = urlParams.get('shop');
            console.log(this.shopParent)

            // Obtener los parámetros de paginación
            const page = parseInt(urlParams.get('page')) || 1;
            const pageSize = parseInt(urlParams.get('pageSize')) || 10;
            this.currentPage = page;
            this.pageSize = pageSize;
            // También podrías dividir el valor si contiene múltiples tags separados por comas
            // this.selectedTagsSearch = this.shopParent.tags.split(',');
            if (this.shopParent.tags) {
                this.selectedTagsSearch = this.shopParent.tags.split(',').filter(tag => tag.trim() !== '');
            }
            // else {
            //   // Convierte el array en una cadena separada por comas
            //   this.shopParent.tags = this.selectedTagsSearch.join(',');
            // }

        },
        saveTicket() {
            console.log("saveTicket")
            // Acceder a los datos del componente hijo
            const orderData = this.$refs.createOrderComponent.getOrderData();

            // hacer dispash
            const url = this.appDomainApi + "shops/tickets/save"
            // const params = {
            //     body: this.orderData
            // }
            axios.post(url, orderData)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.error(err);
                })
        },
        printPdfFrameProvincia() {
            console.log("printPdfFrameProvincia")

        },
        generatePDF() {
            const dataTicket = this.$refs.updateOrderComponent.getOrderData();
            const orderData = dataTicket.ticket
            const orderResto = dataTicket.resto
            console.log(orderData)
            console.log("orderResto")
            console.log(orderResto)
            const ticket = orderData.code_order
            const shop_domain = orderData.shop_store.name
            const client_name = orderData.order_client
            const ticket_order_agency = orderData.order_agency
            const ticket_calle = orderData.order_destination.address 
            const ticket_direccion = orderData.order_destination.department +" - "+ orderData.order_destination.province +" - "+ orderData.order_destination.district
            const ticket_entrega = orderData.date_delivery_at
            const orders = orderData.orders

            const imagePublicidadCapital = "https://static.wixstatic.com/media/0c5b4b_792ae04eb45c4d448929929de829a2ab~mv2.jpg"
            const imageTiendaPapaya = "https://static.wixstatic.com/media/0c5b4b_b12d130c7e4e4feeb4290c3ec29f6be9~mv2.jpg"
            const imagePequeMundos = "https://static.wixstatic.com/media/0c5b4b_7583f9501b8646f5bc32d09dc90cccbc~mv2.png"

            var LogoImage = null

            if (shop_domain=='tiendapapaya.com' || shop_domain==='www.tiendapapaya.com' || shop_domain==='TiendaPapaya') {
                LogoImage = imageTiendaPapaya
            }
            if (shop_domain=='pequemundos.com' || shop_domain==='www.pequemundos.com' || shop_domain==='PequeMundos') {
                LogoImage = imagePequeMundos
            } 
            if (shop_domain=='publicidadcapital.com' || shop_domain==='www.publicidadcapital.com' || shop_domain==='PublicidadCapital') {
                LogoImage = imagePublicidadCapital
            } 

            window.jsPDF = window.jspdf

            const doc = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: [79, 200] // Ancho de 80 mm y altura inicial de 80 mm
            });
            
            const marginLegf = 0;

            // Agregar imagen centrada al inicio
            const imgWidth = 30; // Ancho de la imagen en mm
            const imgHeight = 30; // Alto de la imagen en mm
            const pageWidth = 80; // Ancho de la página en mm

            const imgX = (pageWidth - imgWidth) / 2; // Centrar horizontalmente

            const TextSize = 8;
            const TextLineHeight = 4;

            let y = imgHeight;

            doc.addImage(LogoImage, 'JPEG', imgX, 0, imgWidth, imgHeight);

            if (ticket_direccion) {
                y += TextLineHeight;
                // Añadir "Direccion: " en negrita
                doc.setFontSize(TextSize);
                doc.setFont('helvetica', 'bold');
                doc.text(ticket_direccion, 13, y);
                doc.setFont('helvetica', 'normal');
            }

            if (ticket_calle) {
                y += TextLineHeight;
                // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
                const text_direccion = "Dirección: ";
                const text_ticket_direccion = ticket_calle;
                doc.setFontSize(TextSize);
                doc.setFont("helvetica", "bold");
                doc.text(text_direccion, marginLegf, y);
                const line3Width = doc.getTextWidth(text_direccion);
                doc.setFont("helvetica", "normal");
                doc.text(text_ticket_direccion, marginLegf + line3Width, y);
            }

            if (ticket) {
                y += TextLineHeight;
                // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
                const text_ticket = "Ticket: ";
                const text_client_ticket = ticket;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(TextSize);
                doc.text(text_ticket, marginLegf, y);
                const line6Width = doc.getTextWidth(text_ticket);
                doc.setFont("helvetica", "normal");
                doc.text(text_client_ticket, marginLegf + line6Width, y);
            }

            if (client_name && !ticket_order_agency.agency) {
                y += TextLineHeight;
                // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
                const text_name = "Nombre: ";
                const text_client_name = client_name.name;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(TextSize);
                doc.text(text_name, marginLegf, y);
                const line2Width = doc.getTextWidth(text_name);
                doc.setFont("helvetica", "normal");
                doc.text(text_client_name, marginLegf + line2Width, y);
            } else {
                if (client_name.name_full) {
                    y += TextLineHeight;
                    const text_name = "Nombre: ";
                    const text_client_name = client_name.name_full;
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(TextSize);
                    doc.text(text_name, marginLegf, y);
                    const line2Width = doc.getTextWidth(text_name);
                    doc.setFont("helvetica", "normal");
                    doc.text(text_client_name, marginLegf + line2Width, y);
                }
                if (client_name.document) {
                    y += TextLineHeight;
                    const text_name = "Documento: ";
                    const text_client_name = client_name.document;
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(TextSize);
                    doc.text(text_name, marginLegf, y);
                    const line2Width = doc.getTextWidth(text_name);
                    doc.setFont("helvetica", "normal");
                    doc.text(text_client_name, marginLegf + line2Width, y);
                }
                
                if (ticket_order_agency.destination) {
                    y += TextLineHeight;
                    // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
                    const text_ticket = "Destino: ";
                    const text_client_ticket = ticket_order_agency.destination;
                    doc.setFont("helvetica", "bold");
                    doc.setFontSize(TextSize);
                    doc.text(text_ticket, marginLegf, y);
                    const line6Width = doc.getTextWidth(text_ticket);
                    doc.setFont("helvetica", "normal");
                    doc.text(text_client_ticket, marginLegf + line6Width, y);
                }
            }

            if (client_name && client_name.phone) {
                y += TextLineHeight;
                // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
                const text_name = "Teléfono: ";
                const text_client_name = client_name.phone;
                doc.setFont("helvetica", "bold");
                doc.setFontSize(TextSize);
                doc.text(text_name, marginLegf, y);
                const line2Width = doc.getTextWidth(text_name);
                doc.setFont("helvetica", "normal");
                doc.text(text_client_name, marginLegf + line2Width, y);
            }

            if (orderData.orders[0] && orderData.orders[0].product.title_small) {
                y += TextLineHeight;
                const text_product = "Producto: ";
                const text_ticket_producto = orderData.orders[0].product.title_small;
                doc.setFontSize(TextSize);
                doc.setFont("helvetica", "bold");
                doc.text(text_product, marginLegf, y);
                const line5Width = doc.getTextWidth(text_product);
                doc.setFont("helvetica", "normal");
                doc.text(text_ticket_producto, marginLegf + line5Width, y);
            }
            
            
            if (orderData.date_delivery_at) {
                y += TextLineHeight;            
                const text_entrega = "Entrega: ";
                const text_ticket_entrega = orderData.date_delivery_at;
                doc.setFontSize(TextSize);
                doc.setFont("helvetica", "bold");
                doc.text(text_entrega, marginLegf, y);
                const line6Width = doc.getTextWidth(text_entrega);
                doc.setFont("helvetica", "normal");
                doc.text(text_ticket_entrega, marginLegf + line6Width, y);
            }
            
            if (orderData.order_agency.agency) {
                y += TextLineHeight;            
                const text_agencia = "Agencia: ";
                const text_ticket_agencia = orderData.order_agency.agency;
                doc.setFontSize(TextSize);
                doc.setFont("helvetica", "bold");
                doc.text(text_agencia, marginLegf, y);
                const line6Width = doc.getTextWidth(text_agencia);
                doc.setFont("helvetica", "normal");
                doc.text(text_ticket_agencia, marginLegf + line6Width, y);

                if (orderData.order_agency.destination) {
                    y += TextLineHeight;            
                    const text_desino = "Destino: ";
                    const text_ticket_destino = orderData.order_agency.destination;
                    doc.setFontSize(TextSize);
                    doc.setFont("helvetica", "bold");
                    doc.text(text_desino, marginLegf, y);
                    doc.setFont("helvetica", "normal");
                    doc.text(text_ticket_destino, marginLegf + line6Width, y);
                }
            }
            
            if (orderResto && !orderData.order_agency.agency) {
                y += TextLineHeight;
                const text_resto = "Total: ";
                const text_ticket_resto = formatCurrency(orderResto);
                doc.setFontSize(TextSize);
                doc.setFont("helvetica", "bold");
                doc.text(text_resto, marginLegf, y);
                const line4Width = doc.getTextWidth(text_resto);
                doc.setFont("helvetica", "normal");
                doc.text(text_ticket_resto, marginLegf + line4Width, y);
            }
            


            // Calcular la altura del contenido
            const contentHeight = y - 10; // Ajusta según el último incremento de y y espacio adicional

            // Ajustar la altura del PDF si es necesario
            if (contentHeight > doc.internal.pageSize.height) {
                doc.internal.pageSize.height = contentHeight;
            }
            
            // Convertir el PDF en un blob
            const pdfBlob = doc.output("blob");
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Cargar el blob en el iframe
            const iframe = this.$refs.pdfFrame;
            iframe.src = pdfUrl;

            

            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        },
        generatePDFDescripcion() {
            const orderData = this.$refs.updateOrderComponent.getOrderData();
            console.log(orderData)
            console.log(orderData.code_order)
            console.log(orderData.shop_store.name)
            const ticket = orderData.code_order
            const shop_domain = orderData.shop_store.name
            const client_name = orderData.order_client.name
            const ticket_calle = orderData.order_destination.address 
            const ticket_direccion = orderData.order_destination.department +" - "+ orderData.order_destination.province +" - "+ orderData.order_destination.district
            const ticket_entrega = orderData.date_delivery_at
            const orders = orderData.orders

            const imagePublicidadCapital = "https://static.wixstatic.com/media/0c5b4b_b12d130c7e4e4feeb4290c3ec29f6be9~mv2.jpg"
            const imageTiendaPapaya = "https://static.wixstatic.com/media/0c5b4b_b12d130c7e4e4feeb4290c3ec29f6be9~mv2.jpg"
            const imagePequeMundos = "https://static.wixstatic.com/media/0c5b4b_7583f9501b8646f5bc32d09dc90cccbc~mv2.png"

            var LogoImage = null

            if (shop_domain=='tiendapapaya.com' || shop_domain==='www.tiendapapaya.com' || shop_domain==='TiendaPapaya') {
                LogoImage = imageTiendaPapaya
            }
            if (shop_domain=='pequemundos.com' || shop_domain==='www.pequemundos.com' || shop_domain==='PequeMundos') {
                LogoImage = imagePequeMundos
            } 
            if (shop_domain=='publicidadcapital.com' || shop_domain==='www.publicidadcapital.com' || shop_domain==='PublicidadCapital') {
                LogoImage = imagePublicidadCapital
            } 

            window.jsPDF = window.jspdf

            const doc = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: [79, 200] // Ancho de 80 mm y altura inicial de 80 mm
            });
            
            const marginLegf = 0;

            // Agregar imagen centrada al inicio
            const imgWidth = 30; // Ancho de la imagen en mm
            const imgHeight = 30; // Alto de la imagen en mm
            const pageWidth = 80; // Ancho de la página en mm

            const imgX = (pageWidth - imgWidth) / 2; // Centrar horizontalmente

            const TextSize = 8;
            const TextLineHeight = 4;

            let y = imgHeight;

            doc.addImage(LogoImage, 'JPEG', imgX, 0, imgWidth, imgHeight);

              // Centrar texto "Hello world!" y "2024"
            const text1 = "Ticket: ";
            const text2 = ticket; // Con espacio para separar del texto anterior
            const textWidth = doc.getTextWidth(text1 + text2);
            const textX = (pageWidth - textWidth) / 2;

            // Texto "Hello world!" en negrita
            doc.setFontSize(TextSize);
            doc.setFont("helvetica", "bold");
            doc.text(text1, textX, y + 5);

            y += TextLineHeight;
            // Texto "2024" sin negrita
            const text1Width = doc.getTextWidth(text1);
            doc.setFontSize(TextSize);
            doc.setFont("helvetica", "normal");
            doc.text(text2, textX + text1Width, y);

            y += TextLineHeight;
            // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
            const text_name = "Nombre: ";
            const text_client_name = client_name;
            doc.setFont("helvetica", "bold");
            doc.text(text_name, marginLegf, y);
            const line2Width = doc.getTextWidth(text_name);
            doc.setFont("helvetica", "normal");
            doc.text(text_client_name, marginLegf + line2Width, y);

            y += TextLineHeight;

            // Texto "Nombre: Apellido Completo" con solo el nombre en negrita
            const text_direccion = "Dirección: ";
            const text_ticket_direccion = ticket_calle;
            doc.setFontSize(TextSize);
            doc.setFont("helvetica", "bold");
            doc.text(text_direccion, marginLegf, y);
            const line3Width = doc.getTextWidth(text_direccion);
            doc.setFont("helvetica", "normal");
            doc.text(text_ticket_direccion, marginLegf + line3Width, y);
            
            y += TextLineHeight;

            // Añadir "Direccion: " en negrita
            doc.setFontSize(TextSize);
            doc.setFont('helvetica', 'bold');
            doc.text(ticket_direccion, 13, y);
            doc.setFont('helvetica', 'normal');

            y += TextLineHeight;


            // Definir los productos
            const products = [
                { id: 1, name: "Producto 1", price: "15.00", cantidad: "2" },
                { id: 2, name: "Producto 2", price: "25.00", cantidad: "3" },
                { id: 3, name: "Producto 3", price: "45.00", cantidad: "1" }
            ];

            // Definir símbolo de moneda
            const simbol = "S/. ";

            const alingColumName = 5;
            const alingColumPriceU = 30;
            const alingColumCant = 50;
            const alingColumPriceT = 75;

            // Títulos de las columnas
            doc.setFontSize(8);
            doc.setFont('helvetica', 'bold');
            doc.text('#', 0, y);
            doc.text('Nombre', alingColumName, y);
            doc.text('P. Unit.', alingColumPriceU, y);
            doc.text('Cant.', alingColumCant, y);
            doc.text('P. Tatal', alingColumPriceT, y, null, null, 'right');

            y += TextLineHeight;

            // Imprimir los productos
            doc.setFont('helvetica', 'normal');
            let subtotal = 0;
            products.forEach((product, index) => {
                const { id, name, price, cantidad } = product;

                const precioTotal = price * cantidad;
                subtotal += precioTotal;

                // Imprimir cada fila de producto
                doc.text(String(index + 1), 0, y); // Convertir el índice a cadena
                doc.text(name, alingColumName, y);
                doc.text(formatCurrency(price), alingColumPriceU, y); // Precio unitario
                doc.text(cantidad, alingColumCant, y);
                doc.text(formatCurrency(precioTotal), alingColumPriceT, y, null, null, 'right');
                y += TextLineHeight;
            });

            // Definir el costo de delivery
            const delivery = { id: 1, name: "Delivery", price: 10.00 };

            // Imprimir Delivery
            doc.text('', 10, y);
            doc.text(delivery.name, 30, y);
            doc.text('', 60, y);
            doc.text(formatCurrency(delivery.price), alingColumPriceT, y, null, null, 'right');
            y += TextLineHeight;
            subtotal += delivery.price;

            // Imprimir Subtotal
            doc.setFont('helvetica', 'bold');
            doc.text('Subtotal', 30, y);
            doc.text('', 60, y);
            doc.text(formatCurrency(subtotal), alingColumPriceT, y, null, null, 'right');



            // Calcular la altura del contenido
            const contentHeight = y - 10; // Ajusta según el último incremento de y y espacio adicional

            // Ajustar la altura del PDF si es necesario
            if (contentHeight > doc.internal.pageSize.height) {
                doc.internal.pageSize.height = contentHeight;
            }
            
            // Convertir el PDF en un blob
            const pdfBlob = doc.output("blob");
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Cargar el blob en el iframe
            const iframe = this.$refs.pdfFrame;
            iframe.src = pdfUrl;

            

            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        },
        generatePDF10(){// Crear un nuevo documento PDF con un ancho de 80mm
            const doc = new jsPDF('p', 'mm', [80, 0]); // Altura inicial de 0 para ajustarla más adelante

            // Definir los productos
            const products = [
                { id: 1, name: "Producto 1", price: "15.00", cantidad: "2" },
                { id: 2, name: "Producto 2", price: "25.00", cantidad: "3" },
                { id: 3, name: "Producto 3", price: "45.00", cantidad: "1" }
            ];

            // Definir símbolo de moneda
            const simbol = "S/. ";

            // Añadir contenido al PDF
            let y = 10;

            // Títulos de las columnas
            doc.setFontSize(12);
            doc.setFont('helvetica', 'bold');
            doc.text('Index', 10, y);
            doc.text('Nombre', 30, y);
            doc.text('Cantidad', 70, y);
            doc.text('Precio', 100, y);
            y += 5;

            // Imprimir los productos
            doc.setFont('helvetica', 'normal');
            products.forEach((product, index) => {
                const { id, name, price, cantidad } = product;
                const precioConSimbolo = simbol + price;

                // Imprimir cada fila de producto
                doc.text(String(index + 1), 10, y); // Convertir el índice a cadena
                doc.text(name, 30, y);
                doc.text(cantidad, 70, y);
                doc.text(precioConSimbolo, 100, y);
                y += 10;
            });

            // Calcular la altura del contenido
            const contentHeight = y - 10; // Restar el último incremento de y

            // Ajustar la altura del PDF
            doc.internal.pageSize.height = contentHeight;

            // Crear un Blob del contenido del PDF
            const pdfBlob = doc.output('blob');

            // Crear una URL de objeto para el Blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Abrir esta URL en una nueva ventana o pestaña
            const printWindow = window.open(pdfUrl);

            // Esperar a que la nueva ventana o pestaña cargue el contenido y luego imprimir
            printWindow.onload = () => {
                printWindow.focus();
                printWindow.print();
                // Opcionalmente, puedes cerrar la ventana después de imprimir
                // printWindow.close();
            };
        },
        generatePDF00() {
            // Crear el documento PDF
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: [79, 200] // Ancho de 80 mm y altura inicial de 80 mm
            });

            // Añadir contenido al PDF
            let y = 0;

            // Imprimir Subtotal
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);
            y += 10
            doc.text('Subtotal', 30, y);


            // Calcular la altura del contenido
            const contentHeight = y + 10; // Ajusta según el último incremento de y y espacio adicional

            // Ajustar la altura del PDF si es necesario
            if (contentHeight > doc.internal.pageSize.height) {
                doc.internal.pageSize.height = contentHeight+10;
            }

            // Obtener el blob del PDF
            const pdfBlob = doc.output('blob');

            // Crear una URL para el blob
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Obtener el iframe y establecer la URL del PDF
            // Cargar el blob en el iframe
            const iframe = this.$refs.pdfFrame;
            iframe.src = pdfUrl;

        },
        copy_wsp_client(){
            console.log("copy_wsp")
            const divTextarea = this.$refs.textAreaCopy;
            const dataTicket = this.$refs.updateOrderComponent.getOrderData();
            console.log(dataTicket)

            const orderData = dataTicket.ticket
            const orderResto = dataTicket.resto
            const ticket_code = orderData.code_order
            const shop_domain = orderData.shop_store.name

            const order_client = orderData.order_client
            const order_agency = orderData.order_agency
            const ticket_calle = orderData.order_destination.address 
            const ticket_direccion = orderData.order_destination.department +" - "+ orderData.order_destination.province +" - "+ orderData.order_destination.district
            const ticket_date_entrega = orderData.date_delivery_at
            const orders = orderData.orders

            divTextarea.innerHTML = '';
            divTextarea.innerHTML += "*Resumen de pedido*\n";
            // divTextarea.innerHTML += "\nEmpresa: " + shop_domain;
            if (ticket_code) {
                divTextarea.innerHTML += "\nTicket: " + ticket_code;
            }
            if (order_client.name && !order_agency) {
                divTextarea.innerHTML += "\n\nNombre: " + order_client.name;
            } else {
                divTextarea.innerHTML += "\n\nNombre: " + order_client.name_full;
            }
            if (order_client.document) {
                divTextarea.innerHTML += "\nDNI: " + order_client.document;
            }
            if (order_client.phone) {
                divTextarea.innerHTML += "\nTeléfono: " + order_client.phone;
            }
            if (ticket_direccion && ticket_calle && !order_agency.agency) {
                divTextarea.innerHTML += "\nDirección: " + ticket_calle + ' - ' + ticket_direccion;
            }
            
            divTextarea.innerHTML += "\n";

            if (orders.length>0) {
                orders.forEach((order, index) => {
                    divTextarea.innerHTML += '\n'+(index+1) +'. '+order.product.title_small + ' Cant.: ' + order.amount + ' Subt.: ' + (order.price_discount-order.discount)
                })
            }

            
            divTextarea.innerHTML += "\n";
            
            if (ticket_date_entrega) {
                divTextarea.innerHTML += "\nFecha emisión: " + ticket_date_entrega;
            }
            if (order_agency.agency) {
                divTextarea.innerHTML += "\nAgencia: " + order_agency.agency;
                
                if (order_agency.destination) {
                    divTextarea.innerHTML += "\nDestino: " + order_agency.destination;
                }
                if (ticket_direccion) {
                    divTextarea.innerHTML += "\nEntrega: " + ticket_direccion;
                }
                if (ticket_direccion && ticket_calle) {
                    divTextarea.innerHTML += "\nEntrega: " + ticket_calle + ' - ' + ticket_direccion;
                }
            }

            // divTextarea.innerHTML += "\n";

            // if (dataTicket.totalPrice) {
            //     divTextarea.innerHTML += "\n\nSubtotal: " + dataTicket.totalPrice;
            // }
            if (orderData.delivery_client) {
                divTextarea.innerHTML += "\nDelivery: " + orderData.delivery_client;
            }
            if (dataTicket.totalPrice) {
                divTextarea.innerHTML += "\n*Total: " + dataTicket.totalPrice+'*';
            }
            if (dataTicket.totalSaldo) {
                divTextarea.innerHTML += "\nSaldo: " + dataTicket.totalSaldo;
            }


            
            if (dataTicket.resto !== dataTicket.totalPrice) {
                divTextarea.innerHTML += "\n*Resto: " + dataTicket.resto+'*';
            }

            divTextarea.select();

            try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log(msg)
            } catch (err) {
            console.log(err);
            }
           
        },
        copy_wsp_delivery(){
            console.log("copy_wsp")
            const divTextarea = this.$refs.textAreaCopy;
            const dataTicket = this.$refs.updateOrderComponent.getOrderData();
            console.log(dataTicket)

            const orderData = dataTicket.ticket
            const orderResto = dataTicket.resto
            const ticket_code = orderData.code_order
            const shop_domain = orderData.shop_store.name

            const order_client = orderData.order_client
            const order_agency = orderData.order_agency
            const ticket_calle = orderData.order_destination.address 
            const ticket_direccion = orderData.order_destination.department +" - "+ orderData.order_destination.province +" - "+ orderData.order_destination.district
            const ticket_date_entrega = orderData.date_delivery_at
            const orders = orderData.orders

            divTextarea.innerHTML = '';
            divTextarea.innerHTML += "*Resumen de pedido*\n";
            divTextarea.innerHTML += "\nEmpresa: " + shop_domain;
            if (ticket_code) {
                divTextarea.innerHTML += "\nTicket: " + ticket_code;
            }
            if (order_client.name && !order_client.name_full) {
                divTextarea.innerHTML += "\n\nNombre: " + order_client.name;
            } else {
                divTextarea.innerHTML += "\n\nNombre: " + order_client.name_full;
            }
            if (order_client.document) {
                divTextarea.innerHTML += "\nDNI: " + order_client.document;
            }
            if (order_client.phone) {
                divTextarea.innerHTML += "\nTeléfono: " + order_client.phone;
            }
            if (ticket_direccion && ticket_calle && !order_agency.agency) {
                divTextarea.innerHTML += "\nDirección: " + ticket_calle + ' - ' + ticket_direccion;
            }
            
            divTextarea.innerHTML += "\n";

            if (orders.length>0) {
                orders.forEach((order, index) => {
                    divTextarea.innerHTML += '\n'+(index+1) +' '+order.product.title_small + ' Cant.: ' + order.amount
                })
            }

            
            divTextarea.innerHTML += "\n";
            
            if (ticket_date_entrega) {
                divTextarea.innerHTML += "\nFecha emisión: " + ticket_date_entrega;
            }
            if (order_agency.agency) {
                divTextarea.innerHTML += "\nAgencia: " + order_agency.agency;
                
                if (order_agency.destination) {
                    divTextarea.innerHTML += "\nDestino: " + order_agency.destination;
                }
                if (ticket_direccion) {
                    divTextarea.innerHTML += "\nEntrega: " + ticket_direccion;
                }
                if (ticket_direccion && ticket_calle) {
                    divTextarea.innerHTML += "\nEntrega: " + ticket_calle + ' - ' + ticket_direccion;
                }
            }


            // if (dataTicket.totalDiscount) {
            //     divTextarea.innerHTML += "\n\nSubtotal: " + dataTicket.totalDiscount;
            // }
            // if (orderData.delivery_client) {
            //     divTextarea.innerHTML += "\nDelivery: " + orderData.delivery_client;
            // }
            // if (dataTicket.totalPrice) {
            //     divTextarea.innerHTML += "\nTotal: " + dataTicket.totalPrice;
            // }
            // if (dataTicket.totalSaldo) {
            //     divTextarea.innerHTML += "\nSaldo: " + dataTicket.totalSaldo;
            // }


            
            if (dataTicket) {
                divTextarea.innerHTML += "\n\n*Resto: " + dataTicket.resto+'*';
            }

            divTextarea.select();

            try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log(msg)
            } catch (err) {
            console.log(err);
            }
           
        },
        copy_wsp_buys(){
            console.log("copy_wsp")
            const divTextarea = this.$refs.textAreaCopy;
            const dataTicket = this.$refs.updateOrderComponent.getOrderData();
            console.log(dataTicket)

            const orderData = dataTicket.ticket
            const ticket_code = orderData.code_order
            const shop_domain = orderData.shop_store.name
            const order_client = orderData.order_client

            divTextarea.innerHTML = '';

            let description = "🎉 ¡Felicidades por tu compra, [Nombre del Cliente]! 🎉\n\nGracias por elegir [Nombre de tu tienda]. 🛍️✨ Nos encanta ser parte de tu experiencia de compra.\n\n📦 Tu pedido #[Número de Pedido] está en proceso y pronto te compartiremos los detalles del envío.\n\nSi necesitas ayuda, estamos aquí para ti. ¡Esperamos que disfrutes tu compra! 💖";

            if (shop_domain) {
                description = description.replace(/\[Nombre de tu tienda\]/g, shop_domain+".com");
            }
            if (ticket_code) {
                description = description.replace(/\[Número de Pedido\]/g, ticket_code);
            }
            if (order_client.name) {
                description = description.replace(/\[Nombre del Cliente\]/g, order_client.name);
            }

            divTextarea.innerHTML = description;

            divTextarea.select();

            try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log(msg)
            } catch (err) {
            console.log(err);
            }
           
        },
        copy_wsp_abandoned(){
            console.log("copy_wsp")
            const divTextarea = this.$refs.textAreaCopy;
            const dataTicket = this.$refs.updateOrderComponent.getOrderData();
            console.log(dataTicket)

            const orderData = dataTicket.ticket
            const product_title_small = orderData.orders[0].product.title_small
            const product_title = orderData.orders[0].product.title
            const ticket_code = orderData.code_order
            const shop_domain = orderData.shop_store.name
            const order_client = orderData.order_client

            divTextarea.innerHTML = '';

            let description = "⏳ ¡[Nombre de producto] aún esperando por ti! \n\nHola [Nombre del Cliente], vimos que agregaste [Nombre de producto] a tu carrito, pero no finalizaste tu compra.\n\n💡 ¡Aprovecha antes de que se agote!\n\nSi necesitas ayuda, estamos aquí para asistirte. ¡No dudes en escribirnos! 😊\n\n🌐 [Tu sitio web]\n\n1 Más información\n2 Cancelar";

            if (product_title_small) {
                description = description.replace(/\[Nombre de producto\]/g, product_title_small);
            } else {
                description = description.replace(/\[Nombre de producto\]/g, product_title);
            }
            
            if (shop_domain) {
                description = description.replace(/\[Tu sitio web\]/g, shop_domain+".com");
            }
            
            if (order_client.name) {
                description = description.replace(/\[Nombre del Cliente\]/g, order_client.name);
            }

            divTextarea.innerHTML = description;

            divTextarea.select();

            try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log(msg)
            } catch (err) {
            console.log(err);
            }
           
        },
    },
    computed: {
        urlWithParams() {

            // const baseUrl = this.$route.fullPath; // Reemplaza con tu URL base
            // const baseUrl = 'https://ejemplo.com/miruta'; // Reemplaza con tu URL base

            // const baseUrl = this.$route.fullPath; // Reemplaza con tu URL base
            // const baseUrl = this.pathLocalFront+this.$route.fullPath; // Reemplaza con tu URL base
            // const baseUrl = this.$route.fullPath; // Reemplaza con tu URL base
            const baseUrl = '/tickets'; // Reemplaza con tu URL base
            const queryParams = [];

            // Agrega cada parámetro si su checkbox está seleccionado
            if (this.shopParent.status) {
                queryParams.push('status=' + this.shopParent.status); // Reemplaza 'param1' y 'value1' con tus valores
            }
            if (this.shopParent.shop) {
                queryParams.push('shop=' + this.shopParent.shop); // Reemplaza 'param1' y 'value1' con tus valores
            }
            if (this.shopParent.code) {
                queryParams.push('code_order=' + this.shopParent.code); // Reemplaza 'param1' y 'value1' con tus valores
            }
            if (this.shopParent.search) {
                queryParams.push('search=' + this.shopParent.search); // Reemplaza 'param1' y 'value1' con tus valores
            }

            // Agrega los parámetros de paginación
            // queryParams.push(`page=${this.currentPage}`);
            // queryParams.push(`page=${this.currentPage}`);
            queryParams.push(`page=1`);

            // Combina la URL base con los parámetros
            return baseUrl + (queryParams.length > 0 ? '?' + queryParams.join('&') : '');
        },
        filteredTicket() {
            if (this.selectedOrderId) {
                const item = this.tickets.find(item => item.id === this.selectedOrderId);
                return item;
            } else {
                return []
            }
        },
    },
}

// Función para formatear números como moneda
function formatCurrency(value) {
    console.log("formatCurrency")
    console.log(value)
  return 'S/. ' + parseFloat(value).toFixed(2);
}
</script>

<style scoped>
.order-table {
    width: 100%;
    border-collapse: collapse;
}

.order-table th,
.order-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.order-table .order-item {
    cursor: pointer;
}

.selected {
    background-color: #f0f0f0;
}

.fixed-actions {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 10px;
    border-top: 1px solid #ccc;
    width: 100%;
    text-align: center;
}

.fixed-actions button {
    margin: 0 5px;
}
</style>